<!-- Title Bar -->
<div class="title-bar">
    <div class="title">
        <h1 title="{{ 'NOTIFICATIONS' | translate }}">{{ "NOTIFICATIONS" | translate }}</h1>
    </div>
</div>
<!-- Tabs -->
<div class="tab-container with-router">
    <div class="tabset">
        <ul ngbNav #nav="ngbNav" class="justify-content-start nav nav-tabs">
            <li
                ngbNavItem
                id="incident-config-tab"
                *ngIf="userPermissions.is_zixi_support_write || userPermissions.is_zixi_admin || userPermissions.is_admin || userPermissions.is_objects_manager"
            >
                <a ngbNavLink class="nav-link" title="{{ 'INCIDENTS' | translate }}">{{ "INCIDENTS" | translate }}</a>
                <ng-template ngbNavContent>
                    <app-incident-config></app-incident-config>
                </ng-template>
            </li>
            <li
                ngbNavItem
                id="flapping-detection-tab"
                *ngIf="userPermissions.is_zixi_support_write || userPermissions.is_zixi_admin || userPermissions.is_admin || userPermissions.is_objects_manager"
            >
                <a ngbNavLink class="nav-link" title="{{ 'FLAPPING_DETECTION' | translate }}">{{ "FLAPPING_DETECTION" | translate }}</a>
                <ng-template ngbNavContent>
                    <app-flapping-config></app-flapping-config>
                </ng-template>
            </li>
            <li
                ngbNavItem
                id="kafka-configs-list-tab"
                *ngIf="userPermissions.is_zixi_support_write || userPermissions.is_zixi_admin || userPermissions.is_admin || userPermissions.is_objects_manager"
            >
                <a ngbNavLink class="nav-link" title="{{ 'KAFKA_CONFIG' | translate }}">{{ "KAFKA_CONFIG" | translate }}</a>
                <ng-template ngbNavContent>
                    <app-kafka-config-list></app-kafka-config-list>
                </ng-template>
            </li>
            <li
                ngbNavItem
                id="kafka-configs-list-tab"
                *ngIf="userPermissions.is_zixi_support_write || userPermissions.is_zixi_admin || userPermissions.is_admin || userPermissions.is_objects_manager"
            >
                <a ngbNavLink class="nav-link" title="{{ 'PAGER_DUTY' | translate }}">{{ "PAGER_DUTY" | translate }}</a>
                <ng-template ngbNavContent>
                    <app-pager-duty></app-pager-duty>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
