<app-mini-form-wrapper [saving]="saving" [saved]="saved" (submitted)="onSubmit()" *ngIf="{ pagerDuties: pagerDuties$ | async } && !loading" [formGroup]="form">
    <app-input-generic-field
        [textFieldName]="'NAME' | translate"
        [formControl]="form.controls.name"
        type="text"
        [isParentFormSubmitted]="isFormSubmitted"
        [disabled]="saving"
    ></app-input-generic-field>

    <!-- API_KEY -->
    <app-input-generic-field
        [textFieldName]="'API_KEY' | translate"
        [formControl]="form.controls.api_key"
        type="text"
        [isParentFormSubmitted]="isFormSubmitted"
        [disabled]="saving"
    ></app-input-generic-field>

    <!-- SEND_INCIDENTS -->
    <app-checkbox-field [label]="'SEND_INCIDENTS' | translate" [formControl]="form.controls.send_incidents" [id]="'send_incidents'"></app-checkbox-field>
</app-mini-form-wrapper>
