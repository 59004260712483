<app-mini-form-wrapper [saving]="saving" [saved]="saved" (submitted)="onSubmit()" *ngIf="!loading" [formGroup]="form">
    <app-input-generic-field
        type="number"
        textFieldName=" {{ 'WINDOW' | translate }} [{{ 'MINUTES_L' | translate }}]"
        [formControl]="form.controls.window_minutes"
        [isParentFormSubmitted]="submitted"
    ></app-input-generic-field>

    <app-input-generic-field
        type="number"
        textFieldName=" {{ 'MIN_OBJECTS_COUNT' | translate }}"
        [formControl]="form.controls.min_objects"
        [isParentFormSubmitted]="submitted"
    ></app-input-generic-field>
    <app-input-generic-field
        type="number"
        textFieldName=" {{ 'MIN_OBJECTS_AFFECTED' | translate }}"
        [formControl]="form.controls.percentage_affected"
        [isParentFormSubmitted]="submitted"
    ></app-input-generic-field>
</app-mini-form-wrapper>
