import { Component, OnInit, inject } from "@angular/core";
import { firstValueFrom } from "rxjs";

import { FlappingService } from "./flapping.service";
import { FormBuilder, Validators } from "@angular/forms";

@Component({
    selector: "app-flapping-config",
    templateUrl: "./flapping.component.html"
})
export class FlappingConfigComponent implements OnInit {
    loading = true;
    saving = false;
    saved = false;
    submitted = false;
    private flappingService = inject(FlappingService);
    form = inject(FormBuilder).group({
        window: [5, [Validators.required, Validators.min(5), Validators.max(30)]],
        events: [1, [Validators.required, Validators.min(1), Validators.max(30)]],
        cooldown: [0, [Validators.required, Validators.min(0), Validators.max(60)]]
    });

    async ngOnInit() {
        this.loading = true;
        const flappingConfig = await firstValueFrom(this.flappingService.flappingConfig);
        this.form.setValue(flappingConfig);

        this.loading = false;
    }
    async onSubmit() {
        this.saving = true;
        this.submitted = true;
        if (this.form.valid) {
            await this.flappingService.upsertFlappingConfig(this.form.getRawValue());
            this.saved = true;
        }
        this.saving = false;
    }
}
