import { Component, OnInit, inject } from "@angular/core";
import { firstValueFrom } from "rxjs";

import { IncidentConfigService } from "./incident-config.service";
import { FormBuilder, Validators } from "@angular/forms";

@Component({
    selector: "app-incident-config",
    templateUrl: "./incident-config.component.html"
})
export class IncidentConfigComponent implements OnInit {
    loading = true;
    saving = false;
    saved = false;
    submitted = false;

    private ics = inject(IncidentConfigService);
    form = inject(FormBuilder).group({
        window_minutes: [0, [Validators.required, Validators.min(5), Validators.max(30)]],
        min_objects: [0, [Validators.required, Validators.min(1), Validators.max(30)]],
        percentage_affected: [0, [Validators.required, Validators.min(0), Validators.max(60)]],
        notify: [false]
    });

    async ngOnInit() {
        const incidentsConfig = await firstValueFrom(this.ics.incidentsConfig);
        this.form.setValue(incidentsConfig);

        this.loading = false;
    }

    async onSubmit() {
        this.saving = true;
        this.submitted = true;

        if (this.form.valid) {
            await this.ics.upsertIncidentsConfig(this.form.getRawValue());
            this.saved = true;
        }
        this.saving = false;
    }
}
