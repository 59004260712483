<content-loading-animation *ngIf="loading"></content-loading-animation>
<app-form-wrapper [formGroup]="form" class="content-area pt-0" *ngIf="!loading" [titleMsg]="'KAFKA_CONFIG' | translate" [isEdit]="isEdit">
    <!-- NAME -->
    <app-input-generic-field
        [textFieldName]="'NAME' | translate"
        [formControl]="form.controls.name"
        [isParentFormSubmitted]="isFormSubmitted"
    ></app-input-generic-field>

    <!-- SERVERS -->
    <app-input-generic-field
        [textFieldName]="'SERVERS' | translate"
        [formControl]="form.controls.servers"
        [isParentFormSubmitted]="isFormSubmitted"
        [underLabel]="'KAFKA_HOST_HELP' | translate"
    ></app-input-generic-field>

    <!-- TOPIC -->
    <app-input-generic-field
        [textFieldName]="'TOPIC' | translate"
        [formControl]="form.controls.topic"
        [isParentFormSubmitted]="isFormSubmitted"
    ></app-input-generic-field>

    <!-- USERNAME -->
    <app-input-generic-field
        [textFieldName]="'USERNAME' | translate"
        [formControl]="form.controls.username"
        [isParentFormSubmitted]="isFormSubmitted"
    ></app-input-generic-field>

    <!-- PASSWORD -->
    <app-input-generic-field
        [textFieldName]="'PASSWORD' | translate"
        [formControl]="form.controls.password"
        [isParentFormSubmitted]="isFormSubmitted"
        [underLabel]="isEdit && 'BLANK_SECRET_EDIT' | translate"
    ></app-input-generic-field>

    <!-- AUTHENTICATION -->
    <app-zx-string-selection
        [formControl]="form.controls.auth_mechanism"
        [selectionOptions]="authMechanisms"
        [label]="'AUTHENTICATION' | translate"
        [isParentFormSubmitted]="isFormSubmitted"
    ></app-zx-string-selection>

    <!-- Send Events -->
    <app-checkbox-field [label]="'EVENTS' | translate" [formControl]="form.controls.send_events" [id]="'events'"></app-checkbox-field>
    <!-- Send SCTE -->
    <app-checkbox-field [label]="'SCTE_35' | translate" [formControl]="form.controls.send_scte" [id]="'scend_scte'"></app-checkbox-field>

    <app-zx-form-bottom-buttons
        [isFormValid]="form.valid"
        [(submitted)]="isFormSubmitted"
        (submit)="onSubmit()"
        [cancelUrlNavigate]="constants.urls.configuration.notifications"
        [isSaving]="saving"
        [isEdit]="isEdit"
    ></app-zx-form-bottom-buttons>
</app-form-wrapper>
