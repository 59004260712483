<div class="container-fluid">
    <div class="row">
        <div class="col mt-3 mb-3">
            <p [innerHTML]="'FLAPPING_1' | translate"></p>
            <p [innerHTML]="'FLAPPING_2' | translate"></p>
        </div>
    </div>
</div>
<app-mini-form-wrapper [saving]="saving" [saved]="saved" (submitted)="onSubmit()" *ngIf="!loading" [formGroup]="form">
    <app-input-generic-field
        type="number"
        textFieldName=" {{ 'WINDOW' | translate }} [{{ 'MINUTES_L' | translate }}]"
        [formControl]="form.controls.window"
        [isParentFormSubmitted]="submitted"
    ></app-input-generic-field>

    <app-input-generic-field
        type="number"
        textFieldName="{{ 'STATE_CHANGES' | translate }}"
        [formControl]="form.controls.events"
        [isParentFormSubmitted]="submitted"
    ></app-input-generic-field>
    <app-input-generic-field
        type="number"
        textFieldName="{{ 'COOLDOWN' | translate }} [{{ 'MINUTES_L' | translate }}]"
        [formControl]="form.controls.cooldown"
        [isParentFormSubmitted]="submitted"
    ></app-input-generic-field>
</app-mini-form-wrapper>
